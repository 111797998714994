<template>
  <div>
    <h1 class="text-h4">Управление скидками</h1>
    <v-divider class="my-2"></v-divider>
    <v-card>
      <v-card-title>
        Скидочные программы
        <v-spacer></v-spacer>

        <v-btn icon class="ml-2 mt-1" @click="toggleAdvancedSearch">
          <v-icon>{{ showAdvancedSearch ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-title>
      <transition name="slide">
        <v-card-text v-show="showAdvancedSearch">
          Дополнительные фильтры
        </v-card-text>
      </transition>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="success"
              :disabled="busy"
              v-bind="attrs"
              :to="{ name: 'discount.create' }"
              v-on="on"
            >
              <v-icon>mdi-file-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Добавить</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="error"
              :disabled="!hasSelected"
              class="ml-1"
              v-bind="attrs"
              v-on="on"
              @click="deleteSelectedAction"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Удалить группу</span>
        </v-tooltip>
      </v-card-actions>
      <v-data-table
        v-model="table.selectedItems"
        :loading="busy"
        :headers="table.headers"
        :items="items"
        :server-items-length="itemsTotalCount"
        :footer-props="table.footerProps"
        :options.sync="table.options"
        show-select
        :item-class="tableItemClass"
      >
        <template v-slot:item.id="{ item }">
          {{ item.id | shortenId }}
        </template>

        <template v-slot:item.promotion="{ item }">
          <router-link :to="{ name:'discount.edit',params: {id:item.id} }">{{ item.promotion }}</router-link>
        </template>

        <template v-slot:item.discountTarget="{ item }">
          <v-icon v-if="item.discountTarget==='OrderLinesAmount'">
            mdi-flower-tulip-outline
          </v-icon>
          <v-icon v-else-if="item.discountTarget==='ShippingAmount'">
            mdi-truck-outline
          </v-icon>
        </template>

        <template v-slot:item.discountValue="{ item }">
          <span v-if="item.discountValueType==='Amount'">
            {{ item.discountValue | money }}
          </span>
          <span v-if="item.discountValueType==='Rate'">
            {{ item.discountValue }} %
          </span>
        </template>

        <template v-slot:item.meta.updatedAt="{ item }">
          {{ item.meta.updatedAt | formatDateTime }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            fab
            text
            small
            :to="{ name: 'discount.edit', params: { id: item.id} }"
            class="mr-1"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            @click="deleteAction(item)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="deleteDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="title error--text">Удаление данных</v-card-title>
        <v-card-text class="my-2">
          <div class="text-body-2">
            Вы действительно хотите произвести удаление данных?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="deleteItemConfirm">
            Продолжить
          </v-btn>
          <v-btn text @click="deleteDialog.show = false">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapMutations, mapState } from 'vuex'
import ToastService from '@/service/ToastService'
import { Asc, Desc } from '@/model/SortOrder'
import Page from '@/model/Page'
import _ from 'lodash'
import CategoryFilter from '@/model/category/CategoryFilter'
import DiscountFilter from '@/model/discount/DiscountFilter'

export default {
  ...dashboardPageCommonAttributes,
  name: 'DiscountListPage',
  data: function () {
    return {
      table: {
        selectedItems: [],
        options: {
          sortBy: ['dateStart'],
          sortDesc: [true],
          itemsPerPage: 30
        },
        headers: [
          { text: 'Название', value: 'promotion' },
          { text: 'Цель', value: 'discountTarget' },
          { text: 'Значение', value: 'discountValue' },
          { text: 'Начало', value: 'dateStart' },
          { text: 'Конец', value: 'dateEnd' },
          { text: 'Изменен', value: 'meta.updatedAt' },
          { text: 'Действия', value: 'actions', sortable: false, align: 'right' }
        ],
        filter: {
          search: ''
        },
        footerProps: {
          itemsPerPageOptions: [10, 15, 30, 50],
          itemsPerPageText: 'Строк на странице:',
          // showCurrentPage:true,
          showFirstLastPage: true
        }
      },
      deleteDialog: {
        show: false,
        value: []
      }
    }
  },
  computed: {
    ...mapState('discount_list', ['items', 'busy', 'itemsTotalCount', 'filter', 'showAdvancedSearch']),
    hasSelected() {
      return this.table.selectedItems.length > 0
    }
  },
  watch: {
    'table.options': {
      handler() {
        this.loadTable()
      },
      deep: true
    }
  },
  created() {
    this.table.filter.search = this.filter.search
  },
  methods: {
    ...mapMutations('discount_list', {
      setPage: 'SET_PAGE',
      setFilter: 'SET_FILTER',
      toggleAdvancedSearch: 'TOGGLE_ADVANCED_SEARCH'
    }),
    ...mapActions('discount_list', ['fetchData', 'deleteDiscount']),
    loadTable() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.table.options

      const sort = []

      sortBy.forEach((field, index) => {
        sort.push({
          field,
          order: (sortDesc[index]) ? Desc : Asc
        })
      })

      const p = Page.create({
        page: page - 1,
        size: itemsPerPage,
        sort
      })

      this.setPage({ page: p })

      this.fetchData()
    },
    deleteAction(item) {
      this.deleteDialog.value = [item]
      this.deleteDialog.show = true
    },
    deleteSelectedAction() {
      this.deleteDialog.value = this.table.selectedItems
      this.deleteDialog.show = true
    },
    deleteItemConfirm() {
      const ids = this.deleteDialog.value.map((item) => item.id)

      this.deleteDiscount({ ids })
        .then(() => {
          ToastService.success('Данные сохранены')
          this.deleteDialog.value = []
          this.deleteDialog.show = false
          this.table.selectedItems = []
        })
    },
    onInputFilter: _.debounce(function () {
      const filter = DiscountFilter.create({
        ...this.table.filter
      })

      this.table.options.page = 1

      this.setFilter({ filter })
      this.loadTable()
    }, 500),
    tableItemClass(item) {
      const dateEnd = new Date(item.dateEnd)
      const now = new Date()

      if (dateEnd < now) {
        return ['expired']
      }

      return []
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Скидки'
      }
    }
  }
}
</script>

<style>
.theme--light tr.expired {
  background-color: lightgoldenrodyellow;
}

.theme--dark tr.expired {
  color: lightcoral;
}

</style>
